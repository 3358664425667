<template>

    <div class="column flex-auto" style="margin-bottom:0;">
      <div class="flex-row align-items-center justify-between border-bottom padding-bottom-10">
        <div class=" bold">进销存流向</div>
      </div>
      <el-tabs v-model="curType">
        <el-tab-pane label="采购汇总" name="采购汇总"></el-tab-pane>
        <el-tab-pane label="采购明细" name="采购明细"></el-tab-pane>
        <el-tab-pane label="销售汇总" name="销售汇总"></el-tab-pane>
        <el-tab-pane label="销售明细" name="销售明细"></el-tab-pane>
        <el-tab-pane label="库存" name="库存"></el-tab-pane>
      </el-tabs>
      <cghz v-if="curType=='采购汇总'"></cghz>
      <cgmx v-if="curType=='采购明细'"></cgmx>
      <xshz v-if="curType=='销售汇总'"></xshz>
      <xsmx v-if="curType=='销售明细'"></xsmx>
      <kc v-if="curType=='库存'"></kc>
    </div>

</template>

<script>
import cghz from './flowComponents/cghzFlow'
import cgmx from './flowComponents/cgmxFlow'
import xshz from './flowComponents/xshzFlow'
import xsmx from './flowComponents/xsmxFlow'
import kc from './flowComponents/kcFlow'
export default {
  components: { cghz,cgmx,xshz,xsmx,kc},
  name: 'OrderFlow',
  data() {
    return {
     tabs:[
      '采购汇总','采购明细','销售汇总','销售明细','库存'
     ],
      curType:'采购汇总',
     
    }
  },
  computed:{

  },
  methods:{
   
  },
  created(){

  
    
  },
  mounted(){ 
   
  },
  beforeDestroy() {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
<style>

</style>
